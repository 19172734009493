<template>
  <div style="background:#ffffff;">
    <!-- <van-pull-refresh
      v-model="isLoading"
      style="min-height:100vh"
      @refresh="onRefresh"
    > -->
    <van-address-list
      :list="list"
      default-tag-text="默认"
      disabled
      :switchable="false"
      @add="onAdd"
      @edit="onEdit"
    >
      <template #default>
        <div
          v-if="list.length < 1"
          style="font-size:12px;color:#999999;text-align:center;padding:30px 0"
        >暂无数据</div>
      </template>
    </van-address-list>
    <!-- </van-pull-refresh> -->

    <van-popup
      v-model="show"
      round
      position="bottom"
    >
      <!-- 编辑 -->
      <!-- show-set-default 设置默认地址 -->
      <van-address-edit
        ref='Address'
        :address-info="editAddress"
        :area-list="areaList"
        show-search-result
        :search-result="searchResult"
        show-delete
        @save="onSave"
        @delete="onDelete"
        @change-area="onChangeArea"
        @change-default="onChangeDefault"
        @change-detail="onChangeDetail"
        @select-search='onSecectSeach'
      />
    </van-popup>

    <van-popup
      v-model="addDetail"
      round
      position="bottom"
    >
      <!-- 添加 -->
      <van-address-edit
        ref='Address'
        :area-list="areaList"
        show-search-result
        :search-result="searchResult"
        @change-detail="onChangeDetail"
        @change-area="onChangeArea"
        @select-search='onSecectSeach'
        @save="onSaveDetail"
      />
    </van-popup>
    <div id="allmap"></div>
  </div>
</template>

<script>
import { Toast } from "vant";
import areaList from "../../utils/area.js";
import API from "../../api/http";
export default {
  name: "Saddress",
  data() {
    return {
      areaList,
      show: false,
      addDetail: false,
      isLoading: false,
      list: [],
      editAddress: {},
      searchResult: [],
      check: false,
      timer: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      API.getAddressList().then((res) => {
        let list = res.data.list;
        this.list = list.map((v) => {
          return {
            ...v,
            address: v.province + v.city + v.county,
          };
        });
      });
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        this.getList();
      }, 1000);
    },
    onAdd() {
      this.addDetail = true;
      this.searchResult = [];
      this.editAddress = {};
      // this.editAddress = {
      //   city: "",
      //   county: "",
      //   province: "",
      //   name: "",
      //   tel: "",
      // addressDetail: "",
      //   isDefault: false,
      //   areaCode: "",
      // };
    },
    onSaveDetail(item) {
      //新增
      const obj = {
        id: 0,
        city: item.city,
        county: item.county,
        province: item.province,
        name: item.name,
        tel: item.tel,
        addressDetail: item.addressDetail,
        isDefault: item.isDefault,
        areaCode: item.areaCode,
      };
      API.postAddressAdd(obj).then((res) => {
        this.addDetail = false;

        this.getList();
        Toast("新增成功");
      });
    },
    onEdit(item, index) {
      const obj = {
        id: item.id,
      };

      API.postAddressDetail(obj).then((response) => {
        this.show = true;
        const obj = {
          city: response.data.city,
          county: response.data.county,
          province: response.data.province,
          id: response.data.id,
          isDefault: response.data.isDefault,
          name: response.data.name,
          tel: response.data.tel,
          addressDetail: response.data.addressDetail,
          areaCode: response.data.areaCode,
        };
        this.editAddress = obj;
      });
    },
    onSave(item) {
      //编辑
      const obj = {
        city: item.city,
        county: item.county,
        detailaddr: item.addressDetail,
        // 'district': item.district,
        id: item.id,
        isdefault: item.isdefault,
        province: item.province,
        recivemobile: item.tel,
        recivename: item.name,
        name: item.name,
        tel: item.tel,
        addressDetail: item.addressDetail,
        isDefault: item.isDefault,
        areaCode: item.areaCode,
      };
      API.postAddressAdd(obj).then((response) => {
        this.show = false;
        this.searchResult = [];

        this.getList();
        Toast("保存成功");
      });
    },
    onDelete() {
      const obj = {
        id: this.editAddress.id,
      };
      API.postAddressDel(obj).then((response) => {
        Toast("删除成功");
        this.show = false;
        this.getList();
      });
    },
    onChangeArea(val) {
      this.editAddress.province = val[0].name;
      this.editAddress.city = val[1].name;
      this.editAddress.county = val[2].name;
      this.editAddress.areaCode = val[2].code;
      this.editAddress.addressDetail = "";
      this.$refs.Address.setAddressDetail("");
      this.searchResult = [];
    },
    onChangeDefault(val) {
      this.editAddress.isdefault = val ? 1 : 0;
    },
    onChangeDetail(val) {
      console.log("onChangeDetail", val);

      if (val.trim() === this.editAddress.addressDetail) {
        return;
      }

      if (val.trim() && this.editAddress.province) {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.getAddress(val);
        }, 1000);
      } else {
        console.log("空", val);
        clearTimeout(this.timer);
        this.timer = null;

        this.searchResult = [];
        this.editAddress.addressDetail = "";
        this.$refs.Address.setAddressDetail("");
      }
    },
    getAddress(val) {
      var myGeo = new BMap.Geocoder();
      console.log("myGeo", this.editAddress.province);
      let that = this;
      console.log("myGeo");
      myGeo.getPoint(
        val,
        function (point) {
          if (point) {
            console.log("ll", point);
            let map = new BMap.Map("allmap");
            map.centerAndZoom(new BMap.Point(point.lng, point.lat), 11);
            that.getSeach(map, val);
          }
        },
        this.editAddress.province
      );
    },
    getSeach(map, val) {
      // let map = new BMap.Map("allmap");
      // map.centerAndZoom(new BMap.Point(116.331398, 39.897445), 11);
      // //创建地址解析器实例
      // // 将地址解析结果显示在地图上，并调整地图视野
      let that = this;
      var options = {
        renderOptions: { map: map },
        onSearchComplete: function (results) {
          console.log(results);
          if (local.getStatus() === BMAP_STATUS_SUCCESS) {
            that.searchResult = [];
            for (var i = 0; i <= results.getCurrentNumPois(); i++) {
              let m = results.getPoi(i);

              if (m) {
                let index = m.address.indexOf(that.editAddress.county) || 0;
                let len = that.editAddress.county.length;
                that.searchResult.push({
                  name: m.title || "",
                  address: m.address.substring(index + len),
                });
              }
            }
          } else {
            Toast("您输入的地址没有解析到结果！");
          }
        },
      };
      var local = new BMap.LocalSearch(map, options);
      let name = this.editAddress.city + this.editAddress.county + val;
      local.search(name);
    },
    onSecectSeach(val) {
      console.log("onSecectSeach");
      this.editAddress.addressDetail = val.address + " " + val.name;

      // if (this.$refs.AddressEdit) {
      // this.$refs.Address.setAddressDetail(val.address);
      // }
      // console.log("kk", this.$refs.Address.data);
      // console.log(this.editAddress);
    },
  },
};
</script>
<style lang="scss" scoped>
.van-address-list__bottom {
  padding: 0;
}
.van-address-list__add {
  margin: 0 auto;
}
.van-button--round {
  border-radius: 0;
}
.van-button--danger {
  background: linear-gradient(90deg, #f362b2 0%, #e4337b 100%);
}
.van-address-item {
  border-bottom: thin solid #f6f6f6;
}
</style>
